<template>
    <div>
        <b-button v-b-tooltip.hover title="Envoyer un SMS" variant="light-info" size="md" class="btn-icon" v-b-toggle="uuid">
            <i class="fas fa-comments fa-size"></i>
        </b-button>
        <b-sidebar  v-model="isOpen" :lazy="true" :id="uuid" title="Echange de message" width="600px" backdrop-variant="light-warning" backdrop>
            <div class="side-messages">
                <b-row class="mt-4">
                    <b-col md="4" :offset-md="4">
                        <select class="form-control" v-model="phone">
                            <option v-for="p in lPhones" :value="p" :key="p">{{ p }}</option>
                        </select>
                    </b-col>
                </b-row>
                <div v-for="(l,i) in liste" :key="i">
                    <b-row class="mt-3 px-3" align-v="center">
                        <b-col sm="10" :offset-sm="l.isUser ? '2' : ''">
                            <div class="d-block px-2 py-1 rounded" :class="l.isUser ? 'bg-light-success' : 'bg-secondary'">
                                <p class="font-weight-normal">{{ l.msg }}</p>
                                <div class="d-block text-right">
                                    <small>{{ $api.timestampToDate(l.date_create) }}</small>
                                </div>
                            </div>
                        </b-col>
                    </b-row>                
                </div>                
            </div>            
            <b-form-group label="Message" class="px-3">
                <b-input-group class="mt-3">
                    <template #append>                        
                        <b-dropdown v-b-tooltip.hover title="Envoyer un sms predefini" no-caret class="mr-1" size="sm" variant="light-info">
                            <template #button-content>
                                <i class="fas fa-pen"></i>
                            </template>
                            <b-dropdown-item v-for="(s,i) in sms" :key="i" @click="preview(s.id)">{{s.titre}}</b-dropdown-item>
                        </b-dropdown>
                        <b-button @click="sendSMS()" variant="light-success" class="btn-icon h-100"><i class="fas" :class="load ? 'fa-spin fa-spinner' : 'fa-paper-plane'"></i></b-button>
                    </template>
                    <b-form-textarea v-model="msg" rows="4" :class="size > limit ? 'need-validation' : ''"></b-form-textarea>                    
                </b-input-group>
                <div class="text-right mt-2 pr-10" :class="size > limit ? 'text-danger' : ''">
                    {{ size }} / {{ limit }}
                </div>
            </b-form-group>
        </b-sidebar>
    </div>
</template>
<script>
export default {
    name: "AgendaEMessagevent",
    props:['client_id', 'phones', 'obj', 'type', 'link'],
    components: {        
    },
    beforeCreate() {
    },
    computed:{
        lPhones(){
            var tmp = [];
            this.phones.forEach(e => {
                if(e === null){
                    tmp.push(e);
                }else{
                    var phone = e.toString();                    
                    if(phone.indexOf("0") === 0){                        
                        phone = phone.replace('0','+33');
                    }
                    tmp.push(phone);
                }
            });
            return tmp;
        },
        sms(){
            var type = this.type+"SMS"
            return this.$api.params.Template.filter(t => t.type === type);
        },
        size(){
            if(this.msg === null){
                return 0;
            }
            return this.msg.length;
        }
    },
    watch:{
        isOpen(v){
            if(v === true){
                this.init()
            }
        }
    },
    data() {
        return {
            isOpen: false,
            liste: [],
            load: false,
            phone: null,
            msg: null,
            uuid: this.$api.uuid(),
            limit: this.$api.params.StatusLock.limitSMS  
        }
    },
    methods: {       
        init(){
            this.$api.ajax('/client/message/liste/'+this.client_id, null, res => {
                if(res.status === true){
                    this.liste = res.data;
                    if(this.liste.length > 0){
                        var tmp = JSON.parse(JSON.stringify(this.liste));                        
                        //tmp = tmp.filter(t => t.isUser === true);
                        var last = tmp[tmp.length - 1];                        
                        this.phone = last.to;                        
                    }else {
                        this.phone = this.phones[0] === null ? this.phones[1] : this.phones[0];
                    }
                    setTimeout(() => {
                        var div = document.querySelector(".side-messages");
                        div.scrollBy(0, 1000000);
                    }, 500);
                    this.load = false;
                }
            })
        },
        sendSMS(){
            if(this.load === true || this.msg === null){
                return false;
            }
            if(this.size > this.limit){
                this.$bvToast.toast('Votre message est trop long !', {
                    title: 'Oups !',
                    variant: 'warning',
                    solid: true
                });
                return false;
            }
            if(this.phone === null && this.phone === ""){
                this.$bvToast.toast('Merci de selectionnez un numero de telephone', {
                    title: 'Oups !',
                    variant: 'warning',
                    solid: true
                });
                return false;
            }
            this.load = true;
            var params = {
                to: this.phone,
                client_id: this.client_id,
                msg: this.msg
            }
            this.$api.ajax('/client/message/create', params, res => {
                if(res.status){
                    this.init();
                    this.msg = null;
                }
                this.load = false;
            })
        },
        preview(tpl_id){
            var obj = JSON.parse(JSON.stringify(this.obj));
            obj.link = this.link;
            var params = {
                template_id: tpl_id,
                params:{
                    obj: obj,
                    user: this.$api.user,
                    client_id: this.client_id
                }
            }
            this.$api.ajax('/tpl/preview', params, res => {
                if(res.status){
                    this.msg = res.data
                }
            })
        }
    },
    mounted(){        
        //this.init()
    }
}
</script>
<style scoped>
.side-messages{
    height: 400px;
    overflow: scroll;
}
</style>